import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import {
  addTaste,
  editTaste,
  selectors,
  selectTaste,
} from '../../../../../slices/tastesSlice';
import { selectors as productsSelectors } from '../../../../../slices/productsSlice';
import '../editors.css';
import routes from '../../../../../utils/routes';
import setData from '../../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../../slices/loaderSlice';
import { EditorProps } from '../types/Editor.props';
import { RootState } from '../../../../../slices';

interface FormValues {
  image?: File;
  name: string;
  productId: EntityId;
  code: string;
}

function TastesEditor({ className, onEditorClose }: EditorProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const products = useSelector(productsSelectors.selectAll);
  const tastes = useSelector(selectors.selectAll);
  const selectedTasteId = useSelector<RootState, EntityId>(
    (state) => state.tastes.selectedTasteId,
  );
  const selectedTaste = tastes.filter(
    (Taste) => Taste.id === selectedTasteId,
  )[0];
  const lastTasteId = tastes.length > 0 ? tastes[tastes.length - 1].id : 0;

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    name: yup.string().required('Поле должно быть заполнено'),
    productId: yup.number().required('Поле должно быть заполнено'),
  });
  // code: yup.string()
  //   .required('Поле должно быть заполнено'),
  // image: yup.string()
  //   .required('Поле должно быть заполнено'),

  const initialValues = selectedTasteId
    ? {
        name: selectedTaste.name,
        productId: selectedTaste.productId,
        code: selectedTaste.code,
      }
    : {
        name: '',
        productId: '',
        code: '',
      };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values: FormValues) => {
      const { name, productId, code, image } = values;

      const imageUrl = image ? image.name : '';

      dispatch(changeLoaderVisibility(true));

      const id = Number(lastTasteId) + 1;

      const actions = {
        add: addTaste({
          id,
          name,
          productId,
          code,
          image: imageUrl,
        }),
        edit: editTaste({
          id: selectedTasteId,
          changes: {
            name,
            productId,
            code,
            image: imageUrl,
          },
        }),
      };
      const newData: Record<string, any> = { ...values };

      newData.id = selectedTasteId || id;
      newData.type = selectedTasteId ? 'edit' : 'add';
      newData.file = image;

      const action = () =>
        dispatch(selectedTasteId ? actions.edit : actions.add);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      await setData(routes.setTastesPath(), action, newData, inputRef, options);
      dispatch(changeLoaderVisibility(false));
      dispatch(selectTaste(''));
      onEditorClose?.();
    },
  });

  return (
    <form onSubmit={f.handleSubmit} className={clsx(className, 'editor')}>
      <div className="editor__fields">
        <FloatingLabel
          label="Название характеристики"
          controlId="name"
          className="editor__field"
        >
          <Form.Control
            name="name"
            type="text"
            placeholder="Введите название характеристики"
            ref={inputRef}
            value={f.values.name}
            onChange={f.handleChange}
            isInvalid={f.touched.name && !!f.errors.name}
          />

          <Form.Control.Feedback type="invalid">
            {f.errors.name ? f.errors.name : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Продукт"
          controlId="productId"
          className="editor__field"
        >
          <Form.Select
            aria-label="Продукт"
            name="productId"
            value={f.values.productId}
            onChange={f.handleChange}
            isInvalid={f.touched.productId && !!f.errors.productId}
          >
            <option>Выберете продукт</option>
            {products.map(({ id, name }) => (
              <option key={`taste-products-${id}`} value={id}>
                {name}
              </option>
            ))}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {f.errors.productId ? f.errors.productId : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel label="Код" controlId="code" className="editor__field">
          <Form.Control
            name="code"
            type="text"
            placeholder="Введите код"
            value={f.values.code}
            onChange={f.handleChange}
            isInvalid={f.touched.code && !!f.errors.code}
          />

          <Form.Control.Feedback type="invalid">
            {f.errors.code ? f.errors.code : null}
          </Form.Control.Feedback>
        </FloatingLabel>
      </div>

      <div className="editor__controls">
        <Button type="submit" className="editor__control">
          Добавить продукт
        </Button>

        <Button
          type="button"
          variant="outline-primary"
          onClick={onEditorClose}
          className="editor__control"
        >
          Отмена
        </Button>
      </div>
    </form>
  );
}

export default TastesEditor;
