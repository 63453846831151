import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export interface Category {
  id: EntityId;
  name: string;
}

const categoriesAdapter = createEntityAdapter<Category>();

interface CategoriesState extends EntityState<Category> {
  selectedCategoryId: EntityId;
}

const initialState: CategoriesState = categoriesAdapter.getInitialState({
  selectedCategoryId: '',
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: categoriesAdapter.addOne,
    editCategory: categoriesAdapter.updateOne,
    removeCategory: (state, action: PayloadAction<EntityId>) => {
      categoriesAdapter.removeOne(state, action.payload);
    },
    selectCategory: (state, action: PayloadAction<EntityId>) => {
      state.selectedCategoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, { payload }) => {
      const { categories } = payload;
      const newData: Record<number, Category> = {};
      categories.forEach((item: Category, index: number) => {
        newData[index + 1] = item;
      });
      categoriesAdapter.addMany(state, newData);
    });
  },
});

export const { addCategory, removeCategory, editCategory, selectCategory } =
  categoriesSlice.actions;

export const selectors = categoriesAdapter.getSelectors(
  (state: { categories: CategoriesState }) => state.categories,
);

export default categoriesSlice.reducer;
