import axios from 'axios';
import { toast } from 'react-toastify';
import { RefObject } from 'react';

const setData = async (
  path: string,
  action: () => void,
  values: any,
  inputRef?: RefObject<HTMLInputElement | null>,
  options = {},
  isJson = false,
) => {
  try {
    const params = new FormData();
    if (!isJson) {
      Object.keys(values).map((key) => params.append(key, values[key]));
    } else {
      params.append('values', JSON.stringify(values));
    }
    const res = await axios.post(path, params, options);
    if (res.data.status === 200) {
      action();
      toast.success(res.data.message);
    } else {
      if (inputRef) {
        inputRef.current?.focus();
      }
      toast.error(res.data.message);
    }
  } catch (err: any) {
    if (inputRef) {
      inputRef.current?.focus();
    }
    if (err.isAxiosError && err.response.status === 401) {
      toast.error('Что-то пошло не так, обратитесь к разработке');
      return;
    }
    throw err;
  }
};

export default setData;
