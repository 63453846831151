import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import routes from '../utils/routes';

const fetchData = createAsyncThunk(
  'fetchData',
  async (options: AxiosRequestConfig | undefined) => {
    const response = await axios.get(routes.dataPath(), options);
    return response.data;
  },
);

export default fetchData;
