import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export type Turn = {
  id: EntityId;
  isClosed: boolean;
  endDate: string;
  type: string;
  selectedDate: string;
  startDate: string;
  author: string;
};

const turnsAdapter = createEntityAdapter<Turn>();

type State = EntityState<Turn> & {
  selectedTurnId: EntityId;
  isLoaderVisible: boolean;
};
const initialState: State = turnsAdapter.getInitialState({
  selectedTurnId: '',
  isLoaderVisible: true,
});

const turnsSlice = createSlice({
  name: 'turns',
  initialState,
  reducers: {
    addTurn: turnsAdapter.addOne,
    editTurn: turnsAdapter.updateOne,
    selectTurn: (state, action: PayloadAction<EntityId>) => {
      state.selectedTurnId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, { payload: { turns } }) => {
      state.isLoaderVisible = false;
      const newData: { [key: number]: Turn } = {};
      turns.forEach((item: Turn, index: number) => {
        newData[index + 1] = item;
        newData[index + 1].isClosed = Boolean(item.isClosed);
      });
      turnsAdapter.addMany(state, newData);
    });
  },
});

export const { addTurn, editTurn, selectTurn } = turnsSlice.actions;
export const selectors = turnsAdapter.getSelectors(
  (state: { turns: State }) => state.turns,
);
export default turnsSlice.reducer;
