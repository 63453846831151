import React from 'react';
import './product.css';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';

interface ProductProps {
  className?: string;
  title?: string;
  fields?: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isSelectButtonShown?: boolean;
  isDeleteButtonShown?: boolean;
  onDeleteButtonClick?: () => void;
  isUpdateButtonShown?: boolean;
  onUpdateButtonClick?: () => void;
  isUpdating?: boolean;
  isUpdateDisabled?: boolean;
}

function Product({
  className,
  title,
  fields,
  onClick,
  isSelected,
  isSelectButtonShown,
  isDeleteButtonShown,
  onDeleteButtonClick,
  isUpdateButtonShown,
  onUpdateButtonClick,
  isUpdating,
  isUpdateDisabled,
}: ProductProps) {
  return (
    <div className={clsx(className, 'product')}>
      <div className="product__title">{title}</div>

      {fields && <div className="product__description">{fields}</div>}

      {isSelectButtonShown && (
        <Button
          onClick={onClick}
          variant={isSelected ? 'success' : 'outline-primary'}
          className="product__control"
        >
          {isSelected ? 'Выбран' : 'Выбрать'}
        </Button>
      )}

      {isUpdateButtonShown && (
        <Button
          disabled={isUpdateDisabled || isUpdating}
          variant={isUpdating ? 'success' : 'outline-primary'}
          onClick={onUpdateButtonClick}
          className="product__control"
        >
          {isUpdating ? 'Выбран для изменения' : 'Изменить'}
        </Button>
      )}

      {isDeleteButtonShown && (
        <Button
          onClick={onDeleteButtonClick}
          variant="danger"
          disabled={isUpdateDisabled || isUpdating}
          className="product__control"
        >
          Удалить
        </Button>
      )}
    </div>
  );
}

export default Product;
