import React from 'react';
import { useSelector } from 'react-redux';
import './main.css';
import { EntityId } from '@reduxjs/toolkit';
import Header from '../header/header';
import Loader from '../loader/loader';
import { RootState } from '../../slices';
import { selectors as turnSelectors } from '../../slices/turnSlice';
import TurnsPage from './turns/turns';
import OperationsPage from './operations/operations';
import ProductsPage from './products/products';

function MainPage() {
  const selectedTurnId = useSelector<RootState, EntityId>(
    (state) => state.turns.selectedTurnId,
  );
  const selectedOperationId = useSelector<RootState, EntityId>(
    (state) => state.createdOperations.selectedOperationId,
  );
  const isTurnPageShown = selectedTurnId === '';
  const isOperationPageShown = !isTurnPageShown && selectedOperationId === '';
  const isProductsPageShown = !isTurnPageShown && !isOperationPageShown;

  const turnsList = useSelector(turnSelectors.selectAll);

  const selectedTurn = turnsList.filter(
    (turn) => turn.id === selectedTurnId,
  )[0];

  const isStartLoaderVisible = useSelector<RootState, boolean>(
    (state) => state.turns.isLoaderVisible,
  );
  const isLoaderVisible = useSelector<RootState, boolean>(
    (state) => state.loader.isLoaderVisible,
  );

  return (
    <div className="main-page">
      <Header className="main-page__header" />

      <main className="main-page__content">
        {isTurnPageShown && <TurnsPage className="main-page__item" />}

        {isOperationPageShown && (
          <OperationsPage
            turnId={selectedTurnId}
            isTurnClosed={selectedTurn.isClosed}
            className="main-page__item"
          />
        )}

        {isProductsPageShown && (
          <ProductsPage
            isTurnClosed={selectedTurn.isClosed}
            operationId={selectedOperationId}
            className="main-page__item"
          />
        )}
      </main>

      {(isStartLoaderVisible || isLoaderVisible) && <Loader />}
    </div>
  );
}

export default MainPage;
