import { createContext } from 'react';

interface AuthContextProps {
  loggedIn: boolean;
  logOut?: () => void;
  logIn?: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  loggedIn: false,
});

export default AuthContext;
