import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import {
  addCategory,
  editCategory,
  selectCategory,
  selectors,
} from '../../../../../slices/categoriesSlice';
import '../editors.css';
import routes, { getRoutes } from '../../../../../utils/routes';
import setData from '../../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../../slices/loaderSlice';
import getLastId from '../../../../../hooks/getLastId';
import { RootState, useAppDispatch } from '../../../../../slices';
import { EditorProps } from '../types/Editor.props';

function CategoriesEditor({ className, onEditorClose }: EditorProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const categories = useSelector(selectors.selectAll);
  const categoriesNames = categories.map((item) => item.name);
  const selectedCategoryId: EntityId = useSelector<RootState, EntityId>(
    (state) => state.categories.selectedCategoryId,
  );
  const selectedCategory = categories.filter(
    (category) => category.id === selectedCategoryId,
  )[0];

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    name: yup
      .string()
      .required('Поле должно быть заполнено')
      .notOneOf(categoriesNames, 'Категория с таким именем уже существует'),
  });

  const initialValues = selectedCategoryId
    ? { name: selectedCategory.name }
    : { name: '' };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const { name } = values;

      const newData: Record<string, any> = {};

      dispatch(changeLoaderVisibility(true));
      const lastId = await getLastId(getRoutes.getLastCategoryId());
      const id = Number(lastId) + 1;
      dispatch(changeLoaderVisibility(false));

      const actions = {
        add: addCategory({ id, name }),
        edit: editCategory({
          id: selectedCategoryId,
          changes: {
            name,
          },
        }),
      };

      newData.id = selectedCategoryId || id;
      newData.type = selectedCategoryId ? 'edit' : 'add';
      newData.name = name;
      const action = () =>
        dispatch(selectedCategoryId ? actions.edit : actions.add);
      dispatch(changeLoaderVisibility(true));
      await setData(routes.setCategoryPath(), action, newData, inputRef);
      dispatch(changeLoaderVisibility(false));
      dispatch(selectCategory(''));
      onEditorClose?.();
    },
  });

  return (
    <form onSubmit={f.handleSubmit} className={clsx(className, 'editor')}>
      <div className="editor__fields">
        <FloatingLabel
          label="Имя категории"
          controlId="name"
          className="editor__field"
        >
          <Form.Control
            name="name"
            type="text"
            placeholder="Введите имя категории"
            ref={inputRef}
            value={f.values.name}
            onChange={f.handleChange}
            isInvalid={f.touched.name && !!f.errors.name}
          />

          <Form.Control.Feedback type="invalid">
            {f.errors.name ? f.errors.name : null}
          </Form.Control.Feedback>
        </FloatingLabel>
      </div>

      <div className="editor__controls">
        <Button type="submit" className="editor__control">
          Добавить категорию
        </Button>

        <Button
          type="button"
          variant="outline-primary"
          onClick={onEditorClose}
          className="editor__control"
        >
          Отмена
        </Button>
      </div>
    </form>
  );
}

export default CategoriesEditor;
