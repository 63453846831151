import React from 'react';
import './operations.css';
import clsx from 'clsx';
import { Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { selectors as nomenclaturesSelectors } from '../../../slices/nomenclaturesSlice';
import { selectors as operationsSelectors } from '../../../slices/operationsSlice';
import { selectors as productsSelectors } from '../../../slices/productsSlice';
import { selectors as tastesSelectors } from '../../../slices/tastesSlice';
import {
  addOperation,
  selectOperation,
  selectors,
} from '../../../slices/createdOperationsSlice';
import { editTurn, selectTurn } from '../../../slices/turnSlice';
import routes, { getRoutes } from '../../../utils/routes';
import setData from '../../../hooks/setData';
import { changeLoaderVisibility } from '../../../slices/loaderSlice';
import getLastId from '../../../hooks/getLastId';
import { useAppDispatch } from '../../../slices';

interface OperationsPageProps {
  className?: string;
  turnId: EntityId;
  isTurnClosed: boolean;
}

function OperationsPage({
  className,
  turnId,
  isTurnClosed,
}: OperationsPageProps) {
  const itemData = localStorage.getItem('user') || '{}';
  const { permission } = JSON.parse(itemData);
  const isUserCanEdit = !isTurnClosed || permission > 700;
  const dispatch = useAppDispatch();
  const createdOperationsList = useSelector(selectors.selectAll);

  const onAddNewOperationHandle = async (operationId: EntityId) => {
    const date = new Date(new Date().getTime() + 7 * 3600 * 1000);
    const startDate = date.toISOString().split('.')[0];

    dispatch(changeLoaderVisibility(true));
    const lastId = await getLastId(getRoutes.getLastCreatedOperationId());
    const id = Number(lastId) + 1;
    dispatch(changeLoaderVisibility(false));

    const values = {
      id,
      turnId,
      startDate,
      operationId,
      isSent: false,
      sendFrom: 'Склад',
    };
    const action = () => dispatch(addOperation(values));
    dispatch(changeLoaderVisibility(true));
    await setData(routes.setOperationPath(), action, values);
    dispatch(changeLoaderVisibility(false));
    dispatch(selectOperation(id));
  };
  const nomenclaturesList = useSelector(nomenclaturesSelectors.selectAll);
  const selectedTurnOperations = createdOperationsList.filter(
    (operation) => operation.turnId === turnId,
  );
  const operations = useSelector(operationsSelectors.selectAll);
  const products = useSelector(productsSelectors.selectAll);
  const tastes = useSelector(tastesSelectors.selectAll);

  const onEditHandle = (id: EntityId) => {
    dispatch(selectOperation(id));
  };

  const onBackHandle = () => {
    dispatch(selectTurn(''));
  };

  const onTurnClose = async () => {
    const date = new Date(new Date().getTime() + 7 * 3600 * 1000);
    const endDate = date.toISOString().split('.')[0];
    const values = { id: turnId, endDate };
    const action = () =>
      dispatch(
        editTurn({
          id: turnId,
          changes: { isClosed: true, endDate },
        }),
      );
    dispatch(changeLoaderVisibility(true));
    await setData(routes.closeTurnPath(), action, values);
    dispatch(changeLoaderVisibility(false));
    dispatch(selectTurn(''));
  };

  return (
    <div className={clsx(className, 'operations-page')}>
      {isUserCanEdit && (
        <>
          <h2 className="operations-page__title">Выбрать операцию</h2>

          <div className="operations-page__controls">
            {operations.map((operation) => (
              <Button
                key={`operation-${operation.id}`}
                variant={!operation.isEnabled ? 'secondary' : 'primary'}
                onClick={() => onAddNewOperationHandle(operation.id)}
                disabled={!operation.isEnabled}
                className="operations-page__control"
              >
                {operation.name}
              </Button>
            ))}
          </div>
        </>
      )}

      <h2 className="operations-page__title">Операции на смене</h2>

      <Table striped hover responsive="sm" className="operations-page__table">
        <thead>
          <tr>
            <th>#</th>
            <th>Наименование операции</th>
            <th>Дата создания операции</th>
            <th>Номенклатуры</th>
            <th>Номер операции/документа</th>
            <th>Статус</th>
            <th>Отправитель</th>
            <th>Управление</th>
          </tr>
        </thead>

        <tbody>
          {selectedTurnOperations.map((operation, index) => {
            const operationNomenclatures = nomenclaturesList.filter(
              (item) => item.createdOperationId === operation.id,
            );

            const operationNomenclaturesRenderer = operationNomenclatures.map(
              (item) => {
                const { productId, tasteId } = item;
                const productTitle = products.filter(
                  (product) => product.id === productId,
                )[0];
                const tasteTitle = tastes.filter(
                  (taste) => taste.id === tasteId,
                )[0];
                return (
                  <div
                    key={`nomenc-${item.id}`}
                  >{`${productTitle.name}: ${tasteTitle.name}`}</div>
                );
              },
            );

            const operationName = operations.find(
              (op) => op.id === operation.operationId,
            );

            return (
              <tr key={`operation-id-${operation.id}`}>
                <td>{index + 1}</td>
                <td>{operationName?.name}</td>
                <td>{operation.startDate}</td>
                <td>{operationNomenclaturesRenderer}</td>
                <td>{operation.id}</td>
                <td>{operation.isSent ? 'Отправлено' : 'Не отправлено'}</td>
                <td>{operation.sendFrom}</td>
                <td>
                  <Button
                    onClick={() => onEditHandle(operation.id)}
                    variant={isUserCanEdit ? 'primary' : 'outline-primary'}
                    size="sm"
                  >
                    {isUserCanEdit
                      ? 'Изменить операции и данные'
                      : 'Просмотреть'}
                  </Button>
                </td>
              </tr>
            );
          })}

          {selectedTurnOperations.length === 0 && (
            <tr>
              <td colSpan={8}>Операции отсутствуют, добавьте новую операцию</td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="operations-page__controls">
        <Button
          onClick={onBackHandle}
          variant="outline-primary"
          className="operations-page__control"
        >
          Назад
        </Button>

        {!isTurnClosed && (
          <Button
            onClick={onTurnClose}
            variant="success"
            className="operations-page__control"
          >
            Закрыть смену
          </Button>
        )}
      </div>
    </div>
  );
}

export default OperationsPage;
