import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export interface Operation {
  id: EntityId;
  turnId: EntityId;
  startDate: string;
  isSent: boolean;
  operationId: EntityId;
  sendFrom: string;
}

const createdOperationsAdapter = createEntityAdapter<Operation>();

interface CreatedOperationsState extends EntityState<Operation> {
  selectedOperationId: EntityId;
}

const initialState: CreatedOperationsState =
  createdOperationsAdapter.getInitialState({ selectedOperationId: '' });

const createdOperationsSlice = createSlice({
  name: 'createdOperations',
  initialState,
  reducers: {
    addOperation: createdOperationsAdapter.addOne,
    editOperation: createdOperationsAdapter.updateOne,
    selectOperation: (state, action: PayloadAction<EntityId>) => {
      state.selectedOperationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, { payload }) => {
      const { createdOperations } = payload;
      const newData: Record<number, Operation> = {};
      createdOperations.forEach((item: Operation, index: number) => {
        newData[index + 1] = item;
      });
      createdOperationsAdapter.addMany(state, newData);
    });
  },
});

export const { addOperation, editOperation, selectOperation } =
  createdOperationsSlice.actions;

export const selectors = createdOperationsAdapter.getSelectors(
  (state: { createdOperations: CreatedOperationsState }) =>
    state.createdOperations,
);

export default createdOperationsSlice.reducer;
