import React, { useState } from 'react';
import './manager.css';
import { useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import clsx from 'clsx';
import Header from '../../header/header';
import ManagerPageSection from './sections/sections';
import { selectors as categoriesSelector } from '../../../slices/categoriesSlice';
import { selectors as productsSelector } from '../../../slices/productsSlice';
import { selectors as tastesSelector } from '../../../slices/tastesSlice';
import Loader from '../../loader/loader';
import { RootState } from '../../../slices';
import EditorTypes from './editors/types/EditorTypes';

function ManagerPage() {
  const categories = useSelector(categoriesSelector.selectAll);
  const products = useSelector(productsSelector.selectAll);
  const tastes = useSelector(tastesSelector.selectAll);
  const isStartLoaderVisible = useSelector<RootState, boolean>(
    (state) => state.turns.isLoaderVisible,
  );
  const isLoaderVisible = useSelector<RootState, boolean>(
    (state) => state.loader.isLoaderVisible,
  );
  const sections: Record<string, string> = {
    categories: 'Категории',
    products: 'Продукты',
    tastes: 'Характеристики',
  };
  const [activeSection, setActiveSection] = useState(sections.categories);

  return (
    <div className="manager-page">
      <Header className="manager-page__header" />

      <div className="manager-page__content">
        <aside className="management-page__sidebar management-page-sidebar">
          <ButtonGroup vertical className="management-page-sidebar__controls">
            {Object.keys(sections).map((section) => (
              <Button
                key={section}
                variant={
                  activeSection === sections[section] ? 'primary' : 'light'
                }
                onClick={() => setActiveSection(sections[section])}
                className={clsx('management-page-sidebar__control', {
                  'management-page-sidebar__control--active':
                    activeSection === sections[section],
                })}
              >
                {sections[section]}
              </Button>
            ))}
          </ButtonGroup>
        </aside>

        <main className="management-page__main">
          {activeSection === sections.categories && (
            <ManagerPageSection
              title="Категории"
              editorType={EditorTypes.categories}
              data={categories}
              className="manager-page__section"
            />
          )}

          {activeSection === sections.products && (
            <ManagerPageSection
              title="Продукты"
              editorType={EditorTypes.products}
              data={products}
              className="manager-page__section"
            />
          )}

          {activeSection === sections.tastes && (
            <ManagerPageSection
              title="Характеристики"
              editorType={EditorTypes.tastes}
              data={tastes}
              className="manager-page__section"
            />
          )}
        </main>
      </div>

      {(isStartLoaderVisible || isLoaderVisible) && <Loader />}
    </div>
  );
}

export default ManagerPage;
