import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { removeCategory } from './categoriesSlice';
import fetchData from '../thunks/dataFetchThunk';

export type Product = {
  id: EntityId;
  name: string;
  code: string;
  categoryId: EntityId;
};

const productsAdapter = createEntityAdapter<Product>();

type State = EntityState<Product> & { selectedProductId: EntityId };
const initialState: State = productsAdapter.getInitialState({
  selectedProductId: '',
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addProduct: productsAdapter.addOne,
    editProduct: productsAdapter.updateOne,
    removeProduct: (state, action: PayloadAction<EntityId>) => {
      productsAdapter.removeOne(state, action.payload);
    },
    selectProduct: (state, action: PayloadAction<EntityId>) => {
      state.selectedProductId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload: { products } }) => {
        const newData: { [key: number]: Product } = {};
        products.forEach((item: Product, index: number) => {
          newData[index + 1] = item;
        });
        productsAdapter.addMany(state, newData);
      })
      .addCase(removeCategory, (state, action: PayloadAction<EntityId>) => {
        const removedCategoryId = action.payload;
        const entities = Object.values(state.entities) as Product[];
        const restEntities = entities.filter(
          (e) => e.categoryId !== removedCategoryId,
        );
        productsAdapter.setAll(state, restEntities);
      });
  },
});

export const { addProduct, removeProduct, editProduct, selectProduct } =
  productsSlice.actions;
export const selectors = productsAdapter.getSelectors(
  (state: { products: State }) => state.products,
);
export default productsSlice.reducer;
