import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export interface Nomenclature {
  id: EntityId;
  addDate: string;
  createdOperationId: EntityId;
  productId: EntityId;
  categoryId: EntityId;
  tasteId: EntityId;
  count: number;
  unitId: number;
}

const nomenclaturesAdapter = createEntityAdapter<Nomenclature>();
const initialState: EntityState<Nomenclature> & {
  selectedNomenclatureId: EntityId;
} = nomenclaturesAdapter.getInitialState({ selectedNomenclatureId: '' });
const nomenclaturesSlice = createSlice({
  name: 'nomenclatures',
  initialState,
  reducers: {
    addNomenclature: nomenclaturesAdapter.addOne,
    editNomenclature: nomenclaturesAdapter.updateOne,
    removeNomenclature: (state, action: PayloadAction<EntityId>) => {
      nomenclaturesAdapter.removeOne(state, action.payload);
    },
    selectNomenclature: (state, action: PayloadAction<EntityId>) => {
      state.selectedNomenclatureId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchData.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ nomenclatures: Nomenclature[] }>,
      ) => {
        const { nomenclatures } = payload;
        const newData: { [key: number]: Nomenclature } = {};
        nomenclatures.forEach((item, index) => {
          newData[index + 1] = item;
        });
        nomenclaturesAdapter.addMany(state, newData);
      },
    );
  },
});

export const {
  addNomenclature,
  editNomenclature,
  removeNomenclature,
  selectNomenclature,
} = nomenclaturesSlice.actions;

export const selectors = nomenclaturesAdapter.getSelectors(
  (state: { nomenclatures: EntityState<Nomenclature> }) => state.nomenclatures,
);

export default nomenclaturesSlice.reducer;
