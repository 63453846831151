import React, { useState } from 'react';
import './sections.css';
import clsx from 'clsx';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import CategoriesEditor from '../editors/categories/categories';
import {
  removeCategory,
  selectCategory,
} from '../../../../slices/categoriesSlice';
import { removeProduct, selectProduct } from '../../../../slices/productsSlice';
import { removeTaste, selectTaste } from '../../../../slices/tastesSlice';
import ProductsEditor from '../editors/products/products';
import TastesEditor from '../editors/tastes/tastes';
import routes from '../../../../utils/routes';
import EditorTypes from '../editors/types/EditorTypes';
import { useAppDispatch } from '../../../../slices';

interface ManagerPageSectionProps {
  className: string;
  title: string;
  data: any;
  editorType: EditorTypes;
}

type DataItem = {
  id: 'string';
  [key: string]: any; // или конкретный тип вместо any, если он известен
};

function ManagerPageSection({
  className,
  title,
  data,
  editorType,
}: ManagerPageSectionProps) {
  const [isEditorOpened, setIsEditorOpened] = useState(false);
  const dispatch = useAppDispatch();
  const onEditorClose = () => {
    setIsEditorOpened(false);
  };

  const editors = {
    categories: <CategoriesEditor onEditorClose={onEditorClose} />,
    products: <ProductsEditor onEditorClose={onEditorClose} />,
    tastes: <TastesEditor onEditorClose={onEditorClose} />,
  };

  const removeAction = {
    categories: removeCategory,
    products: removeProduct,
    tastes: removeTaste,
  };

  const selectAction = {
    categories: selectCategory,
    products: selectProduct,
    tastes: selectTaste,
  };

  const removePath = {
    categories: routes.setCategoryPath(),
    products: routes.setProductsPath(),
    tastes: routes.setTastesPath(),
  };

  const onRemoveHandle = async (removeEditorType: EditorTypes, id: string) => {
    const remove = removeAction[removeEditorType];
    const path = removePath[removeEditorType];
    try {
      const params = new FormData();
      params.append('id', id);
      params.append('type', 'delete');
      const res = await axios.post(path, params);
      if (res.data.status === 200) {
        dispatch(remove(id));
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err: any) {
      if (err.isAxiosError && err.response.status === 401) {
        toast.error('Что-то пошло не так, обратитесь к разработке');
        return;
      }
      throw err;
    }
  };

  const onChangeHandle = (changeEditorType: EditorTypes, id: string) => {
    const select = selectAction[changeEditorType];
    dispatch(select(id));
    setIsEditorOpened(true);
  };

  const dataKeys = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div className={clsx(className, 'manager-page-section')}>
      <h2 className="manager-page-section__title">{title}</h2>

      <Table
        striped
        hover
        responsive="sm"
        className="manager-page-section__table"
      >
        {data.length !== 0 && (
          <thead>
            <tr>
              {dataKeys.map((key) => (
                <th key={`key-${editorType}-${key}`}>{key}</th>
              ))}
              <th>Изменение</th>
              <th>Удаление (осторожно)</th>
            </tr>
          </thead>
        )}

        <tbody>
          {data.map((item: DataItem) => (
            <tr key={`row-${editorType}-${item.id}`}>
              {dataKeys.map((key) => (
                <td key={`data-${editorType}-${key}`}>{item[key]}</td>
              ))}
              <td>
                <Button
                  variant="outline-primary"
                  onClick={() => onChangeHandle(editorType, item.id)}
                  size="sm"
                >
                  Изменить
                </Button>
              </td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => onRemoveHandle(editorType, item.id)}
                  size="sm"
                >
                  Удалить
                </Button>
              </td>
            </tr>
          ))}

          {data.length === 0 && (
            <tr>
              <td colSpan={data.keys().length}>Данные отсутствуют</td>
            </tr>
          )}
        </tbody>
      </Table>

      {isEditorOpened && (
        <div className="manager-page-section__editor">
          {editors[editorType]}
        </div>
      )}

      {!isEditorOpened && (
        <Button
          onClick={() => setIsEditorOpened(true)}
          className="manager-page-section__control"
        >
          Добавить
        </Button>
      )}
    </div>
  );
}

export default ManagerPageSection;
