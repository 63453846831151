import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export type Operation = {
  id: EntityId;
  turnId: EntityId;
  isEnabled: boolean;
  name: string;
};

const operationsAdapter = createEntityAdapter<Operation>();

type State = EntityState<Operation> & { selectedOperationId: EntityId };
const initialState: State = operationsAdapter.getInitialState({
  selectedOperationId: '',
});

const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchData.fulfilled,
      (state, { payload: { operations } }) => {
        const newData: { [key: number]: Operation } = {};
        operations.forEach((item: Operation, index: number) => {
          newData[index + 1] = item;
        });
        operationsAdapter.addMany(state, newData);
      },
    );
  },
});

export const selectors = operationsAdapter.getSelectors(
  (state: { operations: State }) => state.operations,
);
export default operationsSlice.reducer;
