import React from 'react';
import './step.css';
import clsx from 'clsx';

interface StepProps {
  className?: string;
  children?: React.ReactNode;
  number: number;
  isFinished?: boolean;
  isGrid?: boolean;
  title: string;
}

function Step({
  className,
  children,
  number,
  isFinished,
  isGrid,
  title,
}: StepProps) {
  return (
    <div
      className={clsx(className, 'step', {
        'step--is-finished': isFinished,
      })}
    >
      <h3 className="step__title">{title}</h3>

      <div className="step__grid">
        <div className="step__number">{number}</div>

        <div
          className={clsx('step__wrapper', {
            'step__wrapper--is-grid': isGrid,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Step;
