import React, { useEffect, useRef } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import './outputForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import {
  addNomenclature,
  editNomenclature,
  selectors,
} from '../../../../slices/nomenclaturesSlice';
import { selectors as unitsSelectors } from '../../../../slices/unitsSlice';
import routes, { getRoutes } from '../../../../utils/routes';
import setData from '../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../slices/loaderSlice';
import getLastId from '../../../../hooks/getLastId';
import { Product } from '../../../../slices/productsSlice';
import { Category } from '../../../../slices/categoriesSlice';
import { Taste } from '../../../../slices/tastesSlice';
import { RootState } from '../../../../slices';

interface OutputFormProps {
  selectedTaste: Taste;
  selectedProduct: Product;
  selectedCategory: Category;
  operationId: EntityId;
  resetData: () => void;
}

function OutputForm({
  selectedTaste,
  selectedProduct,
  selectedCategory,
  operationId,
  resetData,
}: OutputFormProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    count: yup
      .number()
      .min(0, 'Значение должно быть 0 или больше')
      .required('Должно быть заполнено'),
    unitId: yup.number().required('Должно быть заполнено'),
  });

  const dispatch = useDispatch();
  const selectedNomenclatureId = useSelector<RootState, EntityId>(
    (state) => state.nomenclatures.selectedNomenclatureId,
  );
  const nomenclatures = useSelector(selectors.selectAll);
  const units = useSelector(unitsSelectors.selectAll);
  const selectedNomenclature = nomenclatures.filter(
    (item) => item.id === selectedNomenclatureId,
  )[0];

  const initialValues = selectedNomenclature
    ? {
        count: selectedNomenclature.count,
        unitId: selectedNomenclature.unitId,
      }
    : {
        count: '',
        unitId: '',
      };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const date = new Date(new Date().getTime() + 7 * 3600 * 1000);
      const addDate = date.toISOString().split('.')[0];
      const type = selectedNomenclatureId ? 'edit' : 'add';
      const nomenclatureValues = {
        count: Number(values.count),
        unitId: Number(values.unitId),
      };

      if (selectedNomenclatureId) {
        // EDITING NOMENCLATURE
        const preparedValues = {
          id: selectedNomenclatureId,
          ...values,
          type,
        };

        const action = () => {
          dispatch(
            editNomenclature({
              id: selectedNomenclatureId,
              changes: nomenclatureValues,
            }),
          );
        };

        dispatch(changeLoaderVisibility(true));
        await setData(
          routes.setNomenclaturePath(),
          action,
          preparedValues,
          undefined,
          {},
          true,
        );
        dispatch(changeLoaderVisibility(false));
        // ADDING NOMENCLATURE
      } else {
        dispatch(changeLoaderVisibility(true));
        const lastId = await getLastId(getRoutes.getLastNomenclaturesId());
        const id = Number(lastId) + 1;
        dispatch(changeLoaderVisibility(false));

        const nomenclature = {
          id,
          addDate,
          categoryId: selectedCategory.id,
          productId: selectedProduct.id,
          tasteId: selectedTaste.id,
          createdOperationId: operationId,
          ...nomenclatureValues,
        };

        const preparedValues = {
          ...nomenclature,
          type,
        };

        const action = () => {
          dispatch(addNomenclature(nomenclature));
        };
        dispatch(changeLoaderVisibility(true));
        await setData(
          routes.setNomenclaturePath(),
          action,
          preparedValues,
          undefined,
          {},
          true,
        );
        dispatch(changeLoaderVisibility(false));
      }
      resetData();
    },
  });

  const selectedNomenclatureTitle =
    selectedProduct && selectedTaste
      ? `${selectedCategory.name}, ${selectedProduct.name}, ${selectedTaste.name}`
      : '';

  const { values, errors, touched } = f;

  return (
    <Form onSubmit={f.handleSubmit} className="output-form">
      <p className="output-form__description">
        Вы выбрали: {selectedNomenclatureTitle}
      </p>

      <div className="output-form__grid output-form__grid--x2">
        <FloatingLabel
          label="Количество"
          controlId="count"
          className="output-form__field"
        >
          <Form.Control
            name="count"
            type="number"
            placeholder="Введите количество"
            ref={inputRef}
            min={0}
            step={0.25}
            value={values.count}
            onChange={f.handleChange}
            isInvalid={touched.count && !!errors.count}
          />

          <Form.Control.Feedback type="invalid">
            {errors.count ? errors.count : null}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          label="Единица измерения"
          controlId="unitId"
          className="editor__field"
        >
          <Form.Select
            aria-label="Единица измерения"
            name="unitId"
            placeholder="Выберете единицу измерения"
            value={f.values.unitId}
            onChange={f.handleChange}
            isInvalid={f.touched.unitId && !!f.errors.unitId}
          >
            <option>Выберете единицу измерения</option>
            {units.map(({ id, name }) => (
              <option key={`unit-${id}`} value={id}>
                {name}
              </option>
            ))}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {f.errors.unitId ? f.errors.unitId : null}
          </Form.Control.Feedback>
        </FloatingLabel>
      </div>

      <Button type="submit" className="output-form__control">
        {selectedNomenclatureId ? 'Сохранить' : 'Добавить в отчёт'}
      </Button>

      {selectedNomenclatureId && (
        <Button
          type="button"
          onClick={resetData}
          variant="outline-primary"
          className="output-form__control"
        >
          Отменить
        </Button>
      )}
    </Form>
  );
}

export default OutputForm;
