import React from 'react';
import { Button, Table } from 'react-bootstrap';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { selectors, selectTurn } from '../../../../slices/turnSlice';
import { useAppDispatch } from '../../../../slices';

interface TurnsTableProps {
  className?: string;
}

function TurnsTable({ className }: TurnsTableProps) {
  const dispatch = useAppDispatch();
  const turns = useSelector(selectors.selectAll);

  const onTurnEdit = (id: EntityId) => {
    dispatch(selectTurn(id));
  };

  const sortedTurns = turns.sort((a, b) => {
    if (Number(a.id) < Number(b.id)) {
      return -1;
    }
    if (Number(a.id) > Number(b.id)) {
      return 1;
    }
    return 0;
  });

  return (
    <Table
      striped
      hover
      responsive="sm"
      className={clsx(className, 'turns-table')}
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Тип</th>
          <th>Дата смены</th>
          <th>Дата открытия</th>
          <th>Дата закрытия</th>
          <th>Автор</th>
          <th>Номер смены</th>
          <th>Статус</th>
          <th>Управление</th>
        </tr>
      </thead>

      <tbody>
        {sortedTurns.map((turn, index) => (
          <tr key={`turn-${turn.id}`}>
            <td>{index + 1}</td>
            <td>{turn.type}</td>
            <td>{turn.selectedDate}</td>
            <td>{turn.startDate}</td>
            <td>{turn.endDate}</td>
            <td>{turn.author}</td>
            <td>{turn.id}</td>
            <td>{turn.isClosed ? 'Закрыта' : 'Открыта'}</td>
            <td>
              <Button
                onClick={() => onTurnEdit(turn.id)}
                variant={turn.isClosed ? 'primary' : 'outline-primary'}
                size="sm"
              >
                {turn.isClosed ? 'Изменить данные смены' : 'Просмотреть'}
              </Button>
            </td>
          </tr>
        ))}
        {sortedTurns.length === 0 && (
          <tr>
            <td colSpan={9}>Смены отсутствуют, добавьте новую смену</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default TurnsTable;
