import React from 'react';
import './turns.css';
import clsx from 'clsx';
import TurnsForm from './turnsForm/turnsForm';
import TurnsTable from './turnsTable/turnsTable';

interface TurnsPageProps {
  className?: string;
}

function TurnsPage({ className }: TurnsPageProps) {
  return (
    <div className={clsx(className, 'turns-page')}>
      <h2 className="turns-page__title">Последние 10 смен</h2>

      <TurnsTable className="turns-page__table" />

      <h2 className="turns-page__title">Новая смена</h2>

      <TurnsForm className="turns-page__form" />
    </div>
  );
}

export default TurnsPage;
