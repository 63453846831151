import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoaderState {
  isLoaderVisible: boolean;
}

const initialState: LoaderState = {
  isLoaderVisible: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    changeLoaderVisibility: (state, action: PayloadAction<boolean>) => {
      state.isLoaderVisible = action.payload;
    },
  },
});

export const { changeLoaderVisibility } = loaderSlice.actions;

export const selectIsLoaderVisible = (state: { loader: LoaderState }) =>
  state.loader.isLoaderVisible;

export default loaderSlice.reducer;
