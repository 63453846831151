import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import unitsReducer from './unitsSlice';
import nomenclaturesReducer from './nomenclaturesSlice';
import turnsReducer from './turnSlice';
import createdOperationsReducer from './createdOperationsSlice';
import operationsReducer from './operationsSlice';
import productsReducer from './productsSlice';
import categoriesReducer from './categoriesSlice';
import tastesReducer from './tastesSlice';
import loaderReducer from './loaderSlice';

const store = configureStore({
  reducer: {
    units: unitsReducer,
    loader: loaderReducer,
    turns: turnsReducer,
    operations: operationsReducer,
    createdOperations: createdOperationsReducer,
    nomenclatures: nomenclaturesReducer,
    products: productsReducer,
    categories: categoriesReducer,
    tastes: tastesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
