import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { removeProduct } from './productsSlice';
import fetchData from '../thunks/dataFetchThunk';

export type Taste = {
  id: EntityId;
  name: string;
  code: string;
  image: string;
  productId: EntityId;
};

const tastesAdapter = createEntityAdapter<Taste>();

type State = EntityState<Taste> & { selectedTasteId: EntityId };
const initialState: State = tastesAdapter.getInitialState({
  selectedTasteId: '',
});

const tastesSlice = createSlice({
  name: 'tastes',
  initialState,
  reducers: {
    addTaste: tastesAdapter.addOne,
    editTaste: tastesAdapter.updateOne,
    removeTaste: (state, action: PayloadAction<EntityId>) => {
      tastesAdapter.removeOne(state, action.payload);
    },
    selectTaste: (state, action: PayloadAction<EntityId>) => {
      state.selectedTasteId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.fulfilled, (state, { payload: { tastes } }) => {
        const newData: { [key: number]: Taste } = {};
        tastes.forEach((item: Taste, index: number) => {
          newData[index + 1] = item;
        });
        tastesAdapter.addMany(state, newData);
      })
      .addCase(removeProduct, (state, action: PayloadAction<EntityId>) => {
        const removedProductId = action.payload;
        const entities = Object.values(state.entities) as Taste[];
        const restEntities = entities.filter(
          (e) => e.productId !== removedProductId,
        );
        tastesAdapter.setAll(state, restEntities);
      });
  },
});

export const { addTaste, removeTaste, editTaste, selectTaste } =
  tastesSlice.actions;
export const selectors = tastesAdapter.getSelectors(
  (state: { tastes: State }) => state.tastes,
);
export default tastesSlice.reducer;
