import React, { useState } from 'react';
import './products.css';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
} from 'react-bootstrap';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import Step from './step/step';
import Product from './product/product';
import OutputForm from './outputForm/outputForm';
import {
  removeNomenclature,
  selectNomenclature,
  selectors,
} from '../../../slices/nomenclaturesSlice';
import {
  editOperation,
  selectOperation,
  selectors as createdOperationsSelectors,
} from '../../../slices/createdOperationsSlice';
import {
  Category,
  selectors as categoriesSelectors,
} from '../../../slices/categoriesSlice';
import {
  Product as ProductType,
  selectors as productsSelectors,
} from '../../../slices/productsSlice';
import {
  selectors as tastesSelectors,
  Taste,
} from '../../../slices/tastesSlice';
import { selectors as unitsSelectors } from '../../../slices/unitsSlice';
import { changeLoaderVisibility } from '../../../slices/loaderSlice';
import setData from '../../../hooks/setData';
import routes from '../../../utils/routes';
import { RootState } from '../../../slices';
import OperationsSettingsForm from './operationsSettingsForm/operationsSettingsForm';

interface ProductsPageProps {
  className?: string;
  operationId: EntityId;
  isTurnClosed: boolean;
}

function ProductsPage({
  className,
  operationId,
  isTurnClosed,
}: ProductsPageProps) {
  const [selectedCategoryId, setSelectedCategoryId] = useState<EntityId>('');
  const [selectedProductId, setSelectedProductId] = useState<EntityId>('');
  const [selectedTasteId, setSelectedTasteId] = useState<EntityId>('');
  const categories: Category[] = useSelector(categoriesSelectors.selectAll);
  const products: ProductType[] = useSelector(productsSelectors.selectAll);
  const tastes: Taste[] = useSelector(tastesSelectors.selectAll);
  const units = useSelector(unitsSelectors.selectAll);
  const createdOperations = useSelector(createdOperationsSelectors.selectAll);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeItemId, setRemoveItemId] = useState<EntityId>('');
  const removeModalHandleClose = () => setShowRemoveModal(false);
  const removeModalHandleShow = () => setShowRemoveModal(true);

  const selectedNomenclatureId = useSelector<RootState, EntityId>(
    (state) => state.nomenclatures.selectedNomenclatureId,
  );
  const selectedOperationId = useSelector<RootState, EntityId>(
    (state) => state.createdOperations.selectedOperationId,
  );

  const onCategoryChange = (categoryId: EntityId) => {
    setSelectedProductId('');
    setSelectedTasteId('');
    setSelectedCategoryId(categoryId);
  };

  const onProductChange = (productId: EntityId) => {
    setSelectedTasteId('');
    setSelectedProductId(productId);
  };

  const selectedCategory: Category | undefined = categories.find(
    (item) => item.id === selectedCategoryId,
  );
  const selectedProduct: ProductType | undefined = products.find(
    (item) => item.id === selectedProductId,
  );
  const selectedTaste: Taste | undefined = tastes.find(
    (item) => item.id === selectedTasteId,
  );

  const nomenclatures = useSelector(selectors.selectAll);

  const selectedNomenclatures = nomenclatures.filter(
    (nomenclature) => nomenclature.createdOperationId === operationId,
  );

  const selectedOperation = createdOperations.filter(
    (operation) => operation.id === operationId,
  )[0];

  const dispatch = useDispatch();

  const resetData = () => {
    setSelectedCategoryId('');
    setSelectedProductId('');
    setSelectedTasteId('');
    dispatch(selectNomenclature(''));
  };

  const onBackHandle = () => {
    dispatch(selectOperation(''));
    resetData();
  };

  const onOperationSend = async () => {
    const action = () =>
      dispatch(
        editOperation({
          id: selectedOperationId,
          changes: { isSent: true },
        }),
      );

    const user = JSON.parse(localStorage.getItem('user') ?? '{}');

    const preparedValues = {
      id: selectedOperationId,
      sender: user.name,
    };

    dispatch(changeLoaderVisibility(true));
    await setData(routes.sendEmail(), action, preparedValues);
    dispatch(changeLoaderVisibility(false));

    onBackHandle();
  };

  const onNomenclatureDeleteHandle = (id: EntityId) => {
    setRemoveItemId(id);
    removeModalHandleShow();
  };

  const onNomenclatureDelete = async (id: EntityId) => {
    const action = () => dispatch(removeNomenclature(id));
    const preparedValues = { id, type: 'delete' };
    dispatch(changeLoaderVisibility(true));
    await setData(
      routes.setNomenclaturePath(),
      action,
      preparedValues,
      undefined,
      {},
      true,
    );
    dispatch(changeLoaderVisibility(false));
    removeModalHandleClose();
  };

  const onNomenclatureEdit = (
    id: EntityId,
    categoryId: EntityId,
    productId: EntityId,
    tasteId: EntityId,
  ) => {
    setSelectedCategoryId(categoryId);
    setSelectedProductId(productId);
    setSelectedTasteId(tasteId);
    dispatch(selectNomenclature(id));
  };

  const { permission } = JSON.parse(localStorage.getItem('user') ?? '{}');

  const isEditMode = selectedNomenclatureId !== '';
  const isReadOnly = isTurnClosed && permission < 701;
  const isSelectProductAvailable = !selectedOperation.isSent;
  return (
    <div
      className={clsx(className, 'products-page', {
        'product-page--no-gap': selectedOperation.isSent && !isEditMode,
      })}
    >
      {!isReadOnly && (
        <div className="products-page__steps">
          <h2 className="products-page__title">Настройки операции</h2>

          <OperationsSettingsForm operationId={operationId} />
          <br />
          <br />

          {!selectedNomenclatureId && isSelectProductAvailable && (
            <>
              <h2 className="products-page__title">Добавление номенклатуры</h2>

              <Step
                isFinished={selectedCategoryId !== ''}
                number={1}
                title="Выбрать категорию"
                className="products-page__step"
              >
                {categories.map((category) => (
                  <Button
                    key={`category-id-${category.id}`}
                    variant={
                      selectedCategoryId === category.id
                        ? 'success'
                        : 'outline-primary'
                    }
                    type="button"
                    onClick={() => onCategoryChange(category.id)}
                  >
                    {category.name}
                  </Button>
                ))}
              </Step>
            </>
          )}

          {selectedCategoryId && !selectedNomenclatureId ? (
            <Step
              isFinished={selectedProductId !== ''}
              number={2}
              title="Выбрать продукт"
              className="products-page__step"
            >
              {products
                .filter((product) => product.categoryId === selectedCategoryId)
                .map((product) => (
                  <Button
                    key={`product-id-${product.id}`}
                    variant={
                      selectedProductId === product.id
                        ? 'success'
                        : 'outline-primary'
                    }
                    type="button"
                    onClick={() => onProductChange(product.id)}
                  >
                    {product.name}
                  </Button>
                ))}
            </Step>
          ) : null}

          {selectedProductId && !selectedNomenclatureId && (
            <Step
              number={3}
              isGrid
              title="Выбрать характеристику"
              isFinished={selectedTasteId !== ''}
              className="products-page__step"
            >
              {tastes
                .filter((taste) => taste.productId === selectedProductId)
                .map((taste) => (
                  <Product
                    key={`productTaste-id-${taste.id}`}
                    title={taste.name}
                    isSelectButtonShown
                    isSelected={selectedTasteId === taste.id}
                    onClick={() => setSelectedTasteId(taste.id)}
                  />
                ))}
            </Step>
          )}

          {(selectedTasteId || selectedNomenclatureId) && (
            <Step
              number={4}
              title="Заполнить данные"
              className="products-page__step"
            >
              {selectedProduct && selectedTaste && selectedCategory && (
                <OutputForm
                  resetData={resetData}
                  selectedProduct={selectedProduct}
                  selectedTaste={selectedTaste}
                  selectedCategory={selectedCategory}
                  operationId={operationId}
                />
              )}
            </Step>
          )}

          {isSelectProductAvailable && (
            <div className="products-page__controls">
              <Button onClick={onBackHandle} className="products-page__control">
                Назад
              </Button>
            </div>
          )}
        </div>
      )}

      <div
        className={clsx('products-page__total', {
          'products-page__total--full-width':
            isReadOnly || (selectedOperation.isSent && !isEditMode),
        })}
      >
        <h2 className="products-page__title">
          Добавленные номенклатуры
          <br />
          {`Операция/документ #${selectedOperationId}`}
        </h2>

        {selectedNomenclatures.length > 0 ? (
          <ListGroup as="ol" numbered>
            {selectedNomenclatures.map((item) => {
              const currentTaste = tastes
                // eslint-disable-next-line max-len
                .filter(
                  (taste) =>
                    taste.id === item.tasteId &&
                    taste.productId === item.productId,
                )[0];

              const currentProduct = products.filter(
                (product) => product.id === item.productId,
              )[0];

              const unit = units.find(({ id }) => Number(id) === item.unitId);

              const { addDate } = item;

              const itemFields = (
                <Table size="sm" responsive="sm" striped hover>
                  <thead>
                    <tr>
                      <th>Поле</th>
                      <th>Значение</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Дата добавления</td>
                      <td>{addDate}</td>
                    </tr>
                    <tr>
                      <td>Количество</td>
                      <td>{item.count}</td>
                    </tr>
                    <tr>
                      <td>Единица измерения</td>
                      <td>{unit?.shortName}</td>
                    </tr>
                  </tbody>
                </Table>
              );

              return (
                <ListGroupItem as="li" key={`pr${item.id}`}>
                  <Product
                    title={`${currentProduct.name}, ${currentTaste.name}`}
                    isSelectButtonShown={false}
                    isDeleteButtonShown={
                      !isTurnClosed || (isTurnClosed && permission > 700)
                    }
                    onDeleteButtonClick={() =>
                      onNomenclatureDeleteHandle(item.id)
                    }
                    isUpdating={selectedNomenclatureId === item.id}
                    isUpdateButtonShown={!isTurnClosed || permission > 700}
                    fields={itemFields}
                    isUpdateDisabled={!!selectedNomenclatureId}
                    onUpdateButtonClick={() =>
                      onNomenclatureEdit(
                        item.id,
                        item.categoryId,
                        item.productId,
                        item.tasteId,
                      )
                    }
                  />
                </ListGroupItem>
              );
            })}
          </ListGroup>
        ) : (
          <p className="products-page__description">Пока ничего не добавлено</p>
        )}

        <div className="products-page__controls">
          <Button onClick={onBackHandle} className="products-page__control">
            Назад
          </Button>

          {!isReadOnly && (
            <Button
              onClick={onOperationSend}
              variant="success"
              disabled={selectedNomenclatures.length === 0}
              className="products-page__control"
            >
              {!selectedOperation.isSent
                ? 'Отправить отчет'
                : 'Отправить отчет повторно'}
            </Button>
          )}
        </div>
      </div>

      <Modal show={showRemoveModal} onHide={removeModalHandleClose}>
        <Modal.Header>
          <Modal.Title>Удалить номенклатуру</Modal.Title>
        </Modal.Header>

        <Modal.Body>Это действие необратимо. Вы уверены?</Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => onNomenclatureDelete(removeItemId)}
            type="button"
          >
            Удалить
          </Button>

          <Button
            variant="outline-primary"
            onClick={removeModalHandleClose}
            type="button"
          >
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductsPage;
