import React, { useEffect, useRef, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import {
  editOperation,
  selectors,
} from '../../../../slices/createdOperationsSlice';
import routes from '../../../../utils/routes';
import setData from '../../../../hooks/setData';
import { changeLoaderVisibility } from '../../../../slices/loaderSlice';

interface OperationsSettingsFormProps {
  operationId: EntityId;
}

function OperationsSettingsForm({ operationId }: OperationsSettingsFormProps) {
  const [isFormShown, setIsFormShown] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const SignupSchema = yup.object({
    sendFrom: yup.string().required('Должно быть заполнено'),
  });

  const dispatch = useDispatch();
  const createdOperations = useSelector(selectors.selectAll);
  const selectedOperation = createdOperations.filter(
    (item) => item.id === operationId,
  )[0];

  const initialValues = selectedOperation
    ? {
        sendFrom: selectedOperation.sendFrom,
      }
    : {
        sendFrom: 'Склад сырья',
      };

  const f = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const { sendFrom } = values;

      const action = () =>
        dispatch(
          editOperation({
            id: operationId,
            changes: {
              sendFrom,
            },
          }),
        );

      const newData: Record<string, any> = { sendFrom };

      newData.id = operationId;

      dispatch(changeLoaderVisibility(true));
      await setData(
        routes.editOperationPath(),
        action,
        newData,
        undefined,
        {},
        true,
      );
      dispatch(changeLoaderVisibility(false));
      setIsFormShown(false);
    },
  });

  return (
    <>
      {isFormShown ? (
        <Form onSubmit={f.handleSubmit} className="operation-settings-form">
          <FloatingLabel
            label="Отправитель"
            controlId="sendFrom"
            className="editor__field"
          >
            <Form.Select
              aria-label="Отправитель"
              name="sendFrom"
              placeholder="Выберете отправителя"
              value={f.values.sendFrom}
              onChange={f.handleChange}
              isInvalid={f.touched.sendFrom && !!f.errors.sendFrom}
            >
              <option>Выберете отправителя</option>
              <option key={`sender-1`} value={'Склад сырья'}>
                Склад сырья
              </option>
              <option key={`sender-2`} value={'Склад производства'}>
                Склад производства
              </option>
            </Form.Select>

            <Form.Control.Feedback type="invalid">
              {f.errors.sendFrom ? f.errors.sendFrom : null}
            </Form.Control.Feedback>
          </FloatingLabel>

          <br />

          <Button type="submit">Сохранить</Button>
        </Form>
      ) : (
        <>
          <p>
            Отправитель: <b>{selectedOperation.sendFrom}</b>
          </p>

          <Button type="button" onClick={() => setIsFormShown(true)}>
            Изменить
          </Button>
        </>
      )}
    </>
  );
}

export default OperationsSettingsForm;
