import React, { useCallback, useState } from 'react';
import AuthContext from '../contexts/authContext';

interface AuthProviderProps {
  children: React.ReactElement;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem('user'));

  const logIn = useCallback(() => {
    setLoggedIn(true);
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem('user');
    setLoggedIn(false);
  }, []);

  const getUsername = useCallback(() => {
    const user = localStorage.getItem('user') || '';
    const { username } = JSON.parse(user);
    return username;
  }, []);

  const value = React.useMemo(
    () => ({
      loggedIn,
      logIn,
      logOut,
      getUsername,
    }),
    [loggedIn, logIn, logOut, getUsername],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
