import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import fetchData from '../thunks/dataFetchThunk';

export interface Unit {
  id: EntityId;
  name: string;
  shortName: string;
}

const unitsAdapter = createEntityAdapter<Unit>();

interface UnitsState extends EntityState<Unit> {
  selectedCategoryId: EntityId;
}

const initialState: UnitsState = unitsAdapter.getInitialState({
  selectedCategoryId: '',
});

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    addUnit: unitsAdapter.addOne,
    editUnit: unitsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, { payload }) => {
      const { units } = payload;
      const newData: Record<number, Unit> = {};
      units.forEach((item: Unit, index: number) => {
        newData[index + 1] = item;
      });
      unitsAdapter.addMany(state, newData);
    });
  },
});

export const { addUnit, editUnit } = unitsSlice.actions;

export const selectors = unitsAdapter.getSelectors(
  (state: { units: UnitsState }) => state.units,
);

export default unitsSlice.reducer;
